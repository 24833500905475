var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"staff",attrs:{"role":"button"},on:{"click":_vm.showStaffDetail}},[_c('div',{staticClass:"staff-left"},[(
        (_vm.data.image != 'avatar.jpg' &&
          _vm.data.image != '' &&
          _vm.data.image != undefined) ||
        _vm.data.photo
      )?_c('v-avatar',{staticClass:"avator"},[_c('newImg',{attrs:{"fileUrl":'files/' + (_vm.data.image ? _vm.data.image : _vm.data.photo)}})],1):_c('div',[(_vm.data.firstName)?_c('v-avatar',{staticClass:"avator",staticStyle:{"color":"#fff"},attrs:{"color":_vm.avatarBg}},[_c('span',{staticClass:"white--text",staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.data.firstName[0].toUpperCase() + " " + _vm.data.lastName[0].toUpperCase())+" ")])]):_c('v-avatar',{staticClass:"avator",attrs:{"color":_vm.avatarBg}},[_c('span',{staticClass:"white--text",staticStyle:{"font-size":"16px"}},[_vm._v(" "+_vm._s(_vm.data.name[0].toUpperCase())+" ")])])],1),_c('div',{staticClass:"rightContent"},[_c('div',{staticClass:"name"},[_c('p',[_vm._v(_vm._s(_vm.data.name))]),(_vm.data.isFavorite == true && _vm.showFavoriteFunction == true)?_c('img',{attrs:{"src":require("@/assets/images/map/star@2x.png"),"alt":_vm.data.name}}):_vm._e()]),_c('div',{staticClass:"global-title",staticStyle:{"color":"#6c6c6c","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.data.title)+" ")]),_c('div',{staticClass:"global-company",staticStyle:{"color":"#1d1d1d","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.data.company)+" ")]),_c('div',{staticClass:"global-departemt",staticStyle:{"color":"#263a45","font-size":"12px"}},[_vm._v(" "+_vm._s(_vm.data.department)+" ")]),(
          _vm.data.skills &&
          _vm.data.skills.length > 0 &&
          _vm.showAttributesFunction == true
        )?_c('div',{staticClass:"attributes",staticStyle:{"float":"left"}},_vm._l((_vm.data.skills),function(i){return _c('span',{on:{"click":function($event){$event.stopPropagation();return _vm.attritutesBtn(i)}}},[_vm._v(_vm._s(i))])}),0):_vm._e()])],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCheckbox),expression:"showCheckbox"}],staticClass:"checkbox"},[_c('v-checkbox',{on:{"change":_vm.selectPa},model:{value:(_vm.data.chosen),callback:function ($$v) {_vm.$set(_vm.data, "chosen", $$v)},expression:"data.chosen"}})],1),_c('div',{staticClass:"cancel",staticStyle:{"width":"20%","text-align":"right"}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.data.checkedInCampus && _vm.data.checkedInCampus != '' && _vm.data.globalShowCheckIn == 'true'),expression:"data.checkedInCampus && data.checkedInCampus != '' && data.globalShowCheckIn == 'true'"}],staticClass:"checkInDiv"},[_c('img',{attrs:{"src":require("@/assets/images/map/checkmark@2x.png"),"alt":_vm.data.name}}),_c('p',[_vm._v("Checked in")]),_c('p',{staticStyle:{"font-size":"12px","color":"#263a45"}},[_vm._v(" "+_vm._s(_vm.data.checkedInCampus)+" ")])])])])}
var staticRenderFns = []

export { render, staticRenderFns }